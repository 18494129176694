import { isShopify } from './helpers'
import { post } from './network'
import { getSession } from './session'
import { CONSTANTS } from '../constants'

export function publishRodeoLoggedInEvent() {
  try {
    if (isShopify()) {
      if (typeof Shopify !== 'undefined' && Shopify.analytics) {
        Shopify.analytics.publish('rodeo_logged_in')
      } else {
        console.log('Shopify analytics is not properly initialized.')
      }
    } else {
      console.log('Shopify analytics is not available.')

      // Fetch the session ID from local storage or session manager
      const session = getSession()
      const sessionId = session?.sid

      if (!sessionId) {
        console.error('Session ID is missing. Unable to send event data.')
        return
      }

      // Prepare data to send to the API
      const eventData = {
        event_name: 'rodeo_logged_in',
        customer_profile: typeof CURRENT_PROFILE !== 'undefined' ? CURRENT_PROFILE : null
      }

      if (!eventData.customer_profile) {
        console.error('Customer profile is missing or undefined.')
        return
      }

      // Send POST request to the API
      post(CONSTANTS.API_ENDPOINTS.TRACK_ACTIVITY, eventData, sessionId)
        .then(response => {
          if (!response.ok) {
            throw new Error(`API responded with status: ${response.status}`)
          }
          return response.json()
        })
        .then(data => console.log('API Response:', data))
        .catch(error => console.error('Error while sending event data:', error))
    }
  } catch (error) {
    console.error('An unexpected error occurred:', error)
  }
}

import { getState } from './storage'
import { CONSTANTS } from '../constants'
import { serialize } from './helpers'

export function log(...args) {
  logger('log', ...args)
}

export function error(...args) {
  logger('error', ...args)
}

export function logger(level, ...args) {
  const debugging = getState(CONSTANTS.STORAGE_KEYS.DEBUG) || { enabled: false }

  if (!debugging.enabled) {
    return
  }

  console[level](
    '[Cruise Control]',
    ...args.map(arg => (typeof arg === 'string' || arg instanceof Error ? arg : serialize(arg)))
  )
}

/**
 * Logs the Rodeo version banner and initializes the logger.
 * @param {string} version - Version of the Rodeo side cart.
 */
export function logRodeoVersion(version = 'v2.0.1') {
  console.log(
    `%c ► hey.rodeo — Rodeo Effortless Auto-login ${version}`,
    'background: #F8CE47; color: white; padding: 4px; font-size: 14px; font-weight: bold;'
  )
}

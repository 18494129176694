import LZString from 'lz-string'

const LZStringUtil = {
  /**
   * Compress a given string.
   * @param {string} input - The string to compress.
   * @returns {string} - The compressed string.
   */
  compress(input) {
    if (!input || typeof input !== 'string') {
      throw new Error('Input must be a valid string')
    }
    return LZString.compress(input)
  },

  /**
   * Decompress a given compressed string.
   * @param {string} compressed - The compressed string to decompress.
   * @returns {string} - The decompressed string.
   */
  decompress(compressed) {
    if (!compressed || typeof compressed !== 'string') {
      throw new Error('Input must be a valid compressed string')
    }
    return LZString.decompress(compressed)
  },

  /**
   * Compress a string to a Base64 encoded format.
   * @param {string} input - The string to compress.
   * @returns {string} - The compressed Base64 encoded string.
   */
  compressToBase64(input) {
    if (!input || typeof input !== 'string') {
      throw new Error('Input must be a valid string')
    }
    return LZString.compressToBase64(input)
  },

  /**
   * Decompress a Base64 encoded compressed string.
   * @param {string} base64Compressed - The Base64 encoded compressed string.
   * @returns {string} - The decompressed string.
   */
  decompressFromBase64(base64Compressed) {
    if (!base64Compressed || typeof base64Compressed !== 'string') {
      throw new Error('Input must be a valid Base64 encoded compressed string')
    }
    return LZString.decompressFromBase64(base64Compressed)
  },

  /**
   * Compress a string to a UTF-16 format.
   * @param {string} input - The string to compress.
   * @returns {string} - The compressed UTF-16 encoded string.
   */
  compressToUTF16(input) {
    if (!input || typeof input !== 'string') {
      throw new Error('Input must be a valid string')
    }
    return LZString.compressToUTF16(input)
  },

  /**
   * Decompress a UTF-16 encoded compressed string.
   * @param {string} utf16Compressed - The UTF-16 encoded compressed string.
   * @returns {string} - The decompressed string.
   */
  decompressFromUTF16(utf16Compressed) {
    if (!utf16Compressed || typeof utf16Compressed !== 'string') {
      throw new Error('Input must be a valid UTF-16 encoded compressed string')
    }
    return LZString.decompressFromUTF16(utf16Compressed)
  },

  /**
   * Compress a string to a URI-safe format.
   * @param {string} input - The string to compress.
   * @returns {string} - The compressed URI-safe string.
   */
  compressToEncodedURIComponent(input) {
    if (!input || typeof input !== 'string') {
      throw new Error('Input must be a valid string')
    }
    return LZString.compressToEncodedURIComponent(input)
  },

  /**
   * Decompress a URI-safe encoded compressed string.
   * @param {string} uriCompressed - The URI-safe encoded compressed string.
   * @returns {string} - The decompressed string.
   */
  decompressFromEncodedURIComponent(uriCompressed) {
    if (!uriCompressed || typeof uriCompressed !== 'string') {
      throw new Error('Input must be a valid URI-safe encoded compressed string')
    }
    return LZString.decompressFromEncodedURIComponent(uriCompressed)
  }
}

export default LZStringUtil

import { CONSTANTS } from '../constants'
import { setState } from './storage'
import { log } from './logger'
import { post } from './network'
/**
 * Get the current URL as a URL object
 * @returns {URL}
 */
export function getCurrentUrl() {
  return new URL(window.location.href)
}

/**
 * Parse URL parameters into an object
 * @param {URL} url
 * @returns {Object}
 */
export function parseParams(url) {
  return Object.fromEntries(url.searchParams.entries())
}

/**
 * Check if a request is a click request
 * @param {Object} params
 * @returns {boolean}
 */
export function isClickRequest(params) {
  return isAuthRequest(params) // Currently same as auth check
}

/**
 * Toggle debugging state
 * @param {boolean} enabled
 */
export function toggleDebugging(enabled) {
  setState(CONSTANTS.STORAGE_KEYS.DEBUG, { enabled: !!enabled })
}

/**
 * Check if the parameters include authentication tokens
 * @param {Object} params
 * @returns {boolean}
 */
export function isAuthRequest(params) {
  const result = anyParamExists(CONSTANTS.PARAMS.TOKEN_KEYS, params)
  log(CONSTANTS.LOG_MESSAGES.IS_AUTH_REQUEST, result)
  return result
}

/**
 * Check if any of the provided keys exist in params
 * @param {Array} keys
 * @param {Object} params
 * @returns {boolean}
 */
export function anyParamExists(keys, params) {
  return keys.some(key => key in params)
}

/**
 * Remove tokens from the current URL
 */
export function removeTokensFromCurrentLocation() {
  const url = getCurrentUrl()
  CONSTANTS.PARAMS.PROPRIETARY_PARAMS.forEach(key => url.searchParams.delete(key))
  history.replaceState({}, document.title, url)
}

/**
 * Get a specific query parameter from the current URL
 * @param {string} param
 * @returns {string | null}
 */
export function getQueryParam(param) {
  return getCurrentUrl().searchParams.get(param)
}

/**
 * Compact an object by removing undefined, null, empty strings, etc.
 * @param {Object} obj
 * @param {Array} defaults
 * @returns {Object}
 */
export function compactObject(obj, defaults = [undefined, null, NaN, '', 'None']) {
  if (defaults.includes(obj)) return

  if (Array.isArray(obj)) {
    return obj
      .map(v => (v && typeof v === 'object' ? compactObject(v, defaults) : v))
      .filter(v => !defaults.includes(v))
  }

  return Object.entries(obj).reduce((result, [key, value]) => {
    if (!defaults.includes(value)) {
      result[key] = typeof value === 'object' ? compactObject(value, defaults) : value
    }
    return result
  }, {})
}

/**
 * Get the current timestamp in milliseconds
 * @returns {number}
 */
export function now() {
  return Date.now()
}

/**
 * Calculate days between two timestamps
 * @param {number} start
 * @param {number} end
 * @returns {number}
 */
export function daysAgo(start, end = Date.now()) {
  if (!start || !end) return 0
  return (end - start) / (1000 * 60 * 60 * 24)
}

/**
 * Check if a country code belongs to the EU
 * @param {string} countryCode
 * @returns {boolean}
 */
export function isCountryInEU(countryCode) {
  if (!countryCode || typeof countryCode !== 'string') return false
  return CONSTANTS.EU.COUNTRIES.includes(countryCode.toUpperCase())
}

/**
 * Serialize data to JSON
 * @param {Object} data
 * @returns {string}
 */
export function serialize(data) {
  return JSON.stringify(data)
}

/**
 * Create a custom response error
 * @param {string} message
 * @param {Response} response
 * @param {Object} data
 * @returns {Error}
 */
export function createResponseError(message, response, data) {
  const error = new Error(message)
  error.response = response
  error.data = data
  return error
}

/**
 * Get the anchor tag (hash) from a URL
 * @param {string} url
 * @returns {string | null}
 */
export function getAnchorTag(url) {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.hash || null
  } catch (e) {
    log(CONSTANTS.LOG_MESSAGES.INVALID_URL, url)
    return null
  }
}

/**
 * Set "auth in progress" flag in localStorage
 * @param {boolean} inProgress
 */
export function setAuthInProgress(inProgress) {
  localStorage.setItem(CONSTANTS.AUTH_KEYS.IN_PROGRESS, JSON.stringify(inProgress))
}

/**
 * Set "auth in progress" flag in localStorage
 * @param {boolean} inProgress
 */
export function setRedirectInProgress() {
  const data = { initAt: now() } // Create the data object
  localStorage.setItem(CONSTANTS.STORAGE_KEYS.AUTH_REDIRECT, JSON.stringify(data)) // Store as JSON string
}

/**
 * Check if authentication is in progress
 * @returns {boolean}
 */
export function isAuthInProgress() {
  return JSON.parse(localStorage.getItem(CONSTANTS.AUTH_KEYS.IN_PROGRESS) || 'false')
}

/**
 * Check if Shopify analytics is available
 * @returns {boolean}
 */
export function isShopify() {
  return (
    typeof Shopify !== 'undefined' &&
    typeof Shopify.analytics !== 'undefined' &&
    typeof Shopify.analytics.publish === 'function'
  )
}

/**
 * Redirect to a specified URL
 * @param {string} url
 */
export function redirectAway(url) {
  if (!url || url.startsWith('//')) {
    log(CONSTANTS.LOG_MESSAGES.REDIRECT_FALLBACK_INVALID_URL, url)
    return
  }

  const currentUrl = getCurrentUrl()
  const cleanUrl = url.startsWith('/') ? `${currentUrl.origin}${url}` : url

  const matched = [
    currentUrl.origin,
    `https://${window[CONSTANTS.WINDOW_KEYS.PROFILE]?.shop?.domain}`,
    `https://${window[CONSTANTS.WINDOW_KEYS.PROFILE]?.shop?.permanent_domain}`
  ].some(prefix => cleanUrl.startsWith(prefix))

  if (!matched) {
    log(CONSTANTS.LOG_MESSAGES.REDIRECT_FALLBACK_MISMATCH_URL, cleanUrl)
    return
  }

  log(CONSTANTS.LOG_MESSAGES.REDIRECT_AWAY, cleanUrl)

  let finalUrl
  if (cleanUrl.includes('#')) {
    const [baseUrl, hashFragment] = cleanUrl.split('#')
    finalUrl = `${baseUrl}${baseUrl.includes('?') ? '&' : '?'}rodeo=true#${hashFragment}`
  } else {
    finalUrl = cleanUrl
  }

  console.log('Redirecting to:', finalUrl)
  setAuthInProgress(false)
  window.location.href = finalUrl
}
export function filterObject(object, keys, exclude = false) {
  if (!object) {
    return {}
  }

  return Object.keys(object).reduce((result, key) => {
    // include key if not in exclude mode
    if (keys.includes(key) !== exclude) {
      result[key] = object[key]
    }

    return result
  }, {})
}

/**
 * Utility function for timeout wrapped in a Promise.
 * @param {number} ms - The number of milliseconds to wait.
 * @returns {Promise<void>} - A promise that resolves after the given time.
 * @example
 * await wait(2000); // Waits for 2 seconds
 */
export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

/**
 * Builds and returns the API endpoint dynamically.
 *
 * @param {string} path - The path to append to the API endpoint.
 * @returns {string} - The full API endpoint URL.
 */
export function getApiEndpoint() {
  const CURRENT_URL = new URL(window.location.href)

  // Retrieve the shop details or fallback to current host
  const CURRENT_PROFILE = window[CONSTANTS.WINDOW_KEYS.PROFILE]
  const CURRENT_SHOP = CURRENT_PROFILE?.shop || {}

  const API_DOMAIN = CURRENT_SHOP.domain || CURRENT_SHOP.permanent_domain || CURRENT_URL.host

  // Build the base API endpoint
  const API_ENDPOINT = `${CURRENT_URL.protocol}//${API_DOMAIN}${window[CONSTANTS.WINDOW_KEYS.PATH]}/api`

  return API_ENDPOINT
}

export function flattenObject(obj, prefix = '') {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key]
    const newKey = prefix ? `${prefix}-${key}` : key

    if (typeof value === 'object' && value !== null) {
      Object.assign(acc, flattenObject(value, newKey))
    } else {
      acc[newKey] = value
    }

    return acc
  }, {})
}

export async function cruiseToDom(profile) {
  // Create or select the div with class 'rodeo'
  let rodeoDiv = document.querySelector('.rodeo-identity')
  if (rodeoDiv) {
    return
  }
  // Convert product IDs from profile into an array of strings
  const previouslyPurchasedProductsIDs = profile.product_ids || []
  let previouslyPurchasedProductsTitles = []
  // Only fetch product titles if there are product IDs
  if (previouslyPurchasedProductsIDs.length > 0) {
    previouslyPurchasedProductsTitles = await fetchMatchingProducts(previouslyPurchasedProductsIDs)
  }

  // Extract necessary values from the profile
  const requiredData = {
    'data-customer-has-purchase': profile.orders_count > 0 ? 'true' : 'false',
    'data-customer-has-account': profile.is_customer ? 'true' : 'false',
    'customer-tags': profile.tags ? profile.tags.join(',') : '',
    'data-previously-purchased':
      previouslyPurchasedProductsTitles.length > 0 ? previouslyPurchasedProductsTitles.join(', ') : '',
    'data-state': profile.default_shipping_address?.province_code || '',
    'data-country': profile.default_shipping_address?.country_code || '',
    'data-customer-has-subscription': profile.is_subscriber ? 'true' : 'false',
    'data-first_name': profile.first_name || '',
    'data-integrations-loyaltylion-store_credit_balance': profile.integrations?.loyaltylion?.store_credit_balance || '0'
  }

  if (!rodeoDiv) {
    rodeoDiv = document.createElement('div')
    rodeoDiv.className = 'rodeo-identity'
    document.body.appendChild(rodeoDiv)
  }

  // Append the required data attributes to the div
  Object.keys(requiredData).forEach(key => {
    const value = requiredData[key]
    if (value !== undefined && value !== null) {
      rodeoDiv.setAttribute(key, value)
    }
  })
}

async function fetchMatchingProducts(productIDs) {
  const matchingProductsTitles = []

  try {
    const payload = { productIDs }
    const response = await post(CONSTANTS.API_ENDPOINTS.PRODUCT_TITLES, payload, null, true)

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const data = await response.json()

    // Ensure the response contains the expected structure
    if (data.success && data.titles && typeof data.titles === 'object') {
      matchingProductsTitles.push(...Object.values(data.titles)) // Extract only values (titles)
    } else {
      throw new Error('Invalid response format from API')
    }

    // Return only the first 6 titles
    return matchingProductsTitles
  } catch (error) {
    console.error('Error fetching products:', error)
    return []
  }
}

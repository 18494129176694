import { getState } from './storage'
import { CONSTANTS } from '../constants'
import { serialize } from './helpers'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

export function log(...args) {
  logger('log', ...args)
}

export function error(...args) {
  logger('error', ...args)
}

export function logger(level, ...args) {
  const debugging = getState(CONSTANTS.STORAGE_KEYS.DEBUG) || { enabled: false }

  if (!debugging.enabled) {
    return
  }

  console[level](
    '[Cruise Control]',
    ...args.map(arg => (typeof arg === 'string' || arg instanceof Error ? arg : serialize(arg)))
  )
}

/**
 * Logs the Rodeo version banner and initializes the logger.
 * @param {string} version - Version of the Rodeo side cart.
 */
export function logRodeoVersion(version = 'v2.0.1') {
  console.log(
    `%c ► hey.rodeo — Rodeo Effortless Auto-login ${version}`,
    'background: #F8CE47; color: white; padding: 4px; font-size: 14px; font-weight: bold;'
  )
}

/**
 * Sets up error reporting with Sentry for the app-proxy.
 * This ensures client-side errors are properly captured and reported to Sentry.
 * 
 * @param {string} version - Application version for release tracking
 */
export function setupErrorReporting(version = '2.0.1') {
  try {
    // Initialize Sentry in all environments initially to verify functionality
    // We can later disable it for non-production if needed
    const isDev = window.location.hostname === 'localhost' || 
                window.location.hostname.includes('dev') || 
                window.location.hostname.includes('test');
    
    if (isDev) {
      console.log('Sentry initialized in development environment for testing');
    }

    // Get DSN from environment or use fallback
    // In production, this should be injected during build
    const SENTRY_DSN = process.env.SENTRY_DSN || 
                      window.SENTRY_DSN || 
                      null;
    
    // Don't initialize if no DSN is available
    if (!SENTRY_DSN) {
      if (isDev) {
        console.warn('Sentry DSN not configured, error reporting disabled');
      }
      return;
    }

    // Initialize Sentry with proper configuration
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        new BrowserTracing(),
      ],
      // Performance monitoring
      tracesSampleRate: 0.1,
      // Environment setup
      environment: process.env.NODE_ENV || 'production',
      release: 'app-proxy@' + version,
      // Add common tags
      initialScope: {
        tags: {
          component: 'app-proxy'
        }
      },
      // Filter out common noise
      ignoreErrors: [
        // Network errors
        'Network request failed',
        'Failed to fetch',
        'NetworkError',
        // Third-party script errors
        /Script error/i,
        // Other common errors
        'ResizeObserver loop',
        'Non-Error promise rejection'
      ],
      beforeSend(event) {
        // Filter out errors from browser extensions and third-party scripts
        if (event.exception && 
            event.exception.values && 
            event.exception.values[0] && 
            event.exception.values[0].stacktrace && 
            event.exception.values[0].stacktrace.frames) {
          
          const frames = event.exception.values[0].stacktrace.frames;
          
          // Check if error is from a Chrome extension or third-party
          if (frames.some(frame => 
            frame.filename && (
              frame.filename.startsWith('chrome-extension://') || 
              frame.filename.includes('googletagmanager') ||
              frame.filename.includes('facebook')
            )
          )) {
            return null; // Don't send these errors
          }
        }
        
        return event;
      }
    });
    
    if (isDev) {
      console.log('Sentry error reporting initialized');
    }
    
    // Capture unhandled promise rejections
    window.addEventListener('unhandledrejection', function(event) {
      Sentry.captureException(event.reason || new Error('Unhandled Promise rejection'));
    });
    
    // Global error reporting function
    window.reportError = function(error, context = {}) {
      Sentry.withScope(scope => {
        // Tags for Sentry search/filtering
        const importantTags = [
          'shop', 'shop_id', 'shop_domain', 'component', 'feature', 
          'service', 'operation', 'status', 'issue_type', 'session_id',
          'visitor_id', 'event_name', 'customer_id'
        ];
        
        // Add context as tags and extra data
        Object.entries(context).forEach(([key, value]) => {
          // More tags for better organization in Sentry
          if (importantTags.includes(key) || key.endsWith('_id')) {
            scope.setTag(key, String(value));
          } else {
            scope.setExtra(key, value);
          }
        });
        
        // Set some default tags if available from window
        if (window.shopDomain && !context.shop_domain) {
          scope.setTag('shop_domain', window.shopDomain);
        }
        
        if (window.shopId && !context.shop_id) {
          scope.setTag('shop_id', window.shopId);
        }
        
        // Enhanced error capture
        Sentry.captureException(error instanceof Error ? error : new Error(error));
      });
    };
    
  } catch (err) {
    // We can't report this to Sentry since that's what failed,
    // but we can still try to track it in other ways
    
    // In development, show in console
    if (isDev) {
      console.error('Error setting up Sentry:', err);
    }
    
    // Attempt to report through window.onerror for potential other error handlers
    if (window.onerror) {
      try {
        window.onerror('Error setting up Sentry', location.href, 0, 0, err);
      } catch (e) {
        // Ignore - don't let error reporting break the app
      }
    }
  }
}

/**
 * Cruise Control Main Entry Point
 * 
 * IDENTITY & TRACKING FEATURES:
 * 
 * 1. FINGERPRINTING:
 *    - Status: Disabled for EU users only (PR #1019, December 2024)
 *    - Implementation: EU country check in utils/network.js (post function, lines 22-31)
 *    - Related files:
 *      - utils/helpers.js: isCountryInEU() function (lines 125-128)
 *      - constants.js: EU.COUNTRIES list (lines 51-80)
 *    - Purpose: GDPR compliance for EU customers
 *    - To Re-enable: See "EU-Specific Fingerprinting Re-enablement" section in 
 *      /documentation/ai-visitor-id-fingerprint-re-enablement.md
 * 
 * 2. VISITOR ID TRACKING:
 *    - Status: Completely disabled (PR #1377, March 2025)
 *    - Implementation: Multiple redundant safety mechanisms:
 *      a. rodeo-visitor.js script loading commented out (in this file, lines 95-99)
 *      b. Explicit override in CustomerController.php: $rodeoVisitorId = '';
 *      c. Hardcoded flag in TrackActivityController.php: $visitorTrackingEnabled = false;
 *    - Issue: Customer misassociation due to unreliable visitor IDs
 *    - To Re-enable: See comprehensive phased approach in
 *      /documentation/ai-visitor-id-fingerprint-re-enablement.md
 *    - WARNING: Re-enabling requires careful testing to prevent 
 *      customer misassociation issues
 * 
 * 3. BUYER IDENTITY API:
 *    - Status: Disabled (PR #1400, ENG-1446, March 2025)
 *    - Implementation: Disabled in TrackActivityController::addEmailToBuyerIdentity and 
 *      ShopifyStoreService::addEmailToBuyerIdentity
 *    - Issue: Inconsistent email confirmation routing in Shopify
 *    - To Re-enable: Restore code in both methods and review shopify-buyer-identity-analysis.md
 * 
 * 4. CHECKOUT WEBHOOKS:
 *    - Status: Disabled (PR #1421, ENG-1400, March 2025)
 *    - Implementation: Webhook registrations removed in config/shopify-app.php
 *    - Issue: SQS payload size limits (256KB) exceeded
 *    - To Re-enable: Restore webhook registrations and update handlers
 * 
 * 5. EVENT SOURCE FILTERING:
 *    - Status: Simplified in PR #1426 (ENG-1472, April 2025)
 *    - Implementation: Removed complex filtering in TrackActivityController
 *    - Purpose: Reduce complexity while maintaining source tracking
 *    - No re-enabling needed, simplified version is in place
 */

import { CONSTANTS } from './constants'
import { getCurrentUrl, parseParams, isClickRequest, toggleDebugging, delay, cruiseToDom } from './utils/helpers'
import { bootSession } from './bootsession'
import { bootAnalytics } from './rodeoevents'
import { RodeoAnalytics } from './utils/rodeoanalytics'
import getBrowserInfo from './utils/browerdetections'
import { handleCruiseProfile } from './utils/cruiseprofile'
import { RodeoJS } from './utils/rodeojs'
import { loadScripts } from './utils/loadscript'
import { logRodeoVersion, setupErrorReporting } from './utils/logger'
import { publishRodeoLoggedInEvent } from './utils/customevents'
import { getState, setState, removeDeprecatedVisitorCookie } from './utils/storage'
import { syncSession } from './utils/session'

// Initialize error reporting early
setupErrorReporting()
;(function (window, document, location, history, localStorage) {
  
  // Debug message - only show for oatsdev
  if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
    console.error('🔍 CRUISE-CONTROL SCRIPT LOADED - VERSION 3/21/2025')
  }

  // Check if the script has already been executed
  if (window[CONSTANTS.WINDOW_KEYS.SCRIPT_LOADED]) {
    console.error('⚠️ Script already executed once - exiting')
    return // Exit the script if it has already been executed
  }

  // Set a flag indicating that the script has been executed
  window[CONSTANTS.WINDOW_KEYS.SCRIPT_LOADED] = true
  window.addEventListener('message', event => {
    console.log('Message from worker rodeo:', event.data)
    if (event.eventType == 'SHOPIFY_EVENT') {
      // Add validation to check the origin if necessary
      console.log('Message from worker rodeo:', event.data)
    }
  })

  // Define the main execution logic as an async function
  const main = async () => {
    // expose globally so the can be used via window
    window.RodeoJS = RodeoJS
    window.RodeoAnalytics = RodeoAnalytics

    const CURRENT_URL = getCurrentUrl()
    const CURRENT_PARAMS = parseParams(CURRENT_URL)
    const CURRENT_PROFILE = window[CONSTANTS.WINDOW_KEYS.PROFILE]
    const CURRENT_SHOP = CURRENT_PROFILE.shop

    // Debug display URL parameters - only for oatsdev
    if (CURRENT_URL.hostname && CURRENT_URL.hostname.includes('oatsdev')) {
      console.error('📍 PAGE LOAD PARAMETERS:')
      console.error('URL:', CURRENT_URL.href)
      console.error('Parsed parameters:', CURRENT_PARAMS)
      console.error('Has cruise param:', !!CURRENT_PARAMS.cruise)
      console.error('Has utm_pid param:', !!CURRENT_PARAMS.utm_pid)
    }

    const API_DOMAIN = CURRENT_SHOP.domain || CURRENT_SHOP.permanent_domain || CURRENT_URL.host
    const ENDPOINT = `${CURRENT_URL.protocol}//${API_DOMAIN}${window[CONSTANTS.WINDOW_KEYS.PATH]}`
    const SIGNED_IN = !CURRENT_PROFILE.is_visitor
    const IS_CLICK = isClickRequest(CURRENT_PARAMS)
    const SCRIPT_URLS = [CONSTANTS.API_ENDPOINTS.RODEO_VISTOR_SCRIPT]

    logRodeoVersion()

    // Remove deprecated _rodeo_visitor_id cookie (ENG-1536)
    // This is a temporary cleanup that can be removed in a future release
    // Verify production deployment at trycreate.co
    removeDeprecatedVisitorCookie();
    
    /**
     * VISITOR ID DISABLEMENT (March 25, 2025)
     * Loading of rodeo-visitor.js is disabled as part of PR #1377 to prevent visitor ID tracking.
     * This is the primary mechanism for disabling fingerprinting across the entire application.
     * 
     * Additional safety mechanisms exist in:
     * - CustomerController.php: $rodeoVisitorId = '';
     * - TrackActivityController.php: $visitorTrackingEnabled = false;
     * 
     * @see /documentation/ai-visitor-id-fingerprint-re-enablement.md for comprehensive 
     * re-enablement instructions and the recommended phased approach to mitigate risks.
     * 
     * WARNING: Do not uncomment without first reviewing the documentation and
     * implementing proper testing to prevent customer misassociation issues.
     */
    // await loadScripts(SCRIPT_URLS, {
    //   maxRetries: 3, // Retry up to 3 times
    //   maxDelay: 1000, // Base retry delay of 1 second
    //   retryLimit: 3000 // Max delay of 3 seconds
    // })
    /**
     * DEBUGGING
     */
    window.cruise_debugging = toggleDebugging

    // Start the boot session
    try {
      await bootSession(SIGNED_IN, CURRENT_PROFILE, IS_CLICK, CURRENT_PARAMS, CURRENT_URL)
    } catch (error) {
      console.error(CONSTANTS.MESSAGES.AUTH_CALLBACK_ERROR, error)
      
      // Report critical error to Sentry with context
      if (window.reportError) {
        window.reportError(error, {
          component: 'bootSession',
          shop: CURRENT_SHOP.domain || CURRENT_SHOP.permanent_domain,
          signed_in: SIGNED_IN,
          is_click: IS_CLICK,
          url: CURRENT_URL.href,
          params: JSON.stringify(CURRENT_PARAMS)
        });
      }
    }

    bootAnalytics()

    if (getState(CONSTANTS.STORAGE_KEYS.AUTH_REDIRECT)) {
      await delay(2000)
      publishRodeoLoggedInEvent()
      setState(CONSTANTS.STORAGE_KEYS.AUTH_REDIRECT, null)
    }
    syncSession()

    if (window.cruise_profile) {
      if (window.rodeo_data_attributes) {
        try {
          await cruiseToDom(window.cruise_profile)
        } catch (error) {
          console.error('Error in cruiseToDom:', error)
        }
      } else {
        // Skip rendering when rodeo_data_attributes is not available
        // (Happens when theme extension is not installed)
        
        // console.log('rodeo_data_attributes not available. Rendering skipped.');
      }
    }

    getBrowserInfo().then(async result => {
      if (!result.isPrivate) {
        await delay(3000) // Wait for 3 seconds added
        handleCruiseProfile()
      } else {
        console.warn('private mode detected, skipping profile handling.')
      }
    })
  }

  // Call the async function
  main()
})(window, document, location, history, localStorage)

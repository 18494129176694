import { getRawState } from './storage'
import { CONSTANTS } from '../constants'
/**
 * Fetch Visitor ID and Cache it
 */
export async function getVisitorID() {
  //if we have the cachedVisitorId return that
  const cachedVisitorId = getRawState(CONSTANTS.STORAGE_KEYS.VISITOR_ID)
  if (cachedVisitorId) return cachedVisitorId

  return window.RodeoVisitorId
}

import { log, error } from './logger'
import { CONSTANTS } from '../constants'
import { post } from './network' // Reusable POST function
import { updateSession } from './session'
import {
  createResponseError,
  now,
  getQueryParam,
  redirectAway,
  anyParamExists,
  getAnchorTag,
  setAuthInProgress,
  setRedirectInProgress
} from './helpers'
import { setAccessToken, getCookie } from './storage'
/**
 * Resume Authentication Flow
 * @param {Object} jsonData - Data payload for the auth resume request.
 * @param {string|null} [sessionId=null] - Optional session ID.
 * @returns {Object} - Payload data from the API response.
 * @throws {Error} - Throws an error for failed requests.
 */
export async function apiAuthResume(jsonData, sessionId = null) {
  setAuthInProgress(true)
  let response

  try {
    response = await post(CONSTANTS.API_ENDPOINTS.CUSTOMER_RESUME, jsonData, sessionId)
  } catch (e) {
    error(CONSTANTS.MESSAGES.AUTH_RESUME_ERROR, e)
    throw e
  }

  const payload = await response.json().catch(e => {
    error(CONSTANTS.MESSAGES.JSON_PARSE_ERROR, e)
    return { message: e.message }
  })

  log(CONSTANTS.LOG_MESSAGES.AUTH_RESPONSE_PAYLOAD, payload)

  if (!response.ok) {
    throw createResponseError(payload?.message || response.statusText, response, payload)
  }

  if (!payload?.data) {
    throw createResponseError(CONSTANTS.MESSAGES.AUTH_RESUME_SESSION_ERROR, response, payload)
  }

  log(CONSTANTS.LOG_MESSAGES.AUTH_DATA, payload.data, payload.message)

  return payload.data
}

/**
 * Initiate Authentication Flow
 * @param {Object} params - Parameters for initiating authentication.
 * @param {string|null} [sessionId=null] - Optional session ID.
 * @returns {boolean} - True if the auth flow was initiated, otherwise false.
 */
export async function initiateAuthFlow(params, sessionId = null) {
  if (!isAuthRequest(params) && !sessionId) {
    return false
  }

  log(CONSTANTS.LOG_MESSAGES.AUTH_INITIATED, params, sessionId)

  let session = updateSession({ authInitAt: now() })

  try {
    const response = await apiAuth({ ...params, url: encodeURIComponent(window.location.href) }, sessionId)

    const { redirectTo, sessionId: newSessionId, authEnabled, accessToken } = response

    log(CONSTANTS.LOG_MESSAGES.AUTH_SUCCESS_REDIRECT, redirectTo, newSessionId)

    /* Debug for oatsdev only - commented out for production
    if (window.location.hostname.includes('oatsdev')) {
      console.error('🔑 AUTH RESPONSE RECEIVED');
      console.error(`Has access token: ${accessToken ? 'YES' : 'NO'}`);
      console.error(`Session ID: ${newSessionId ? newSessionId : 'NONE'}`);
      console.error(`Auth enabled: ${authEnabled ? 'YES' : 'NO'}`);
    }

    // Enhanced debug for oatsdev store only
    if (window.location.hostname.includes('oatsdev')) {
      console.error('==== API DEBUG FOR OATSDEV ====');
      console.error(`Has accessToken in response: ${accessToken ? 'YES' : 'NO'}`);
      console.error(`Response structure: ${JSON.stringify({
        redirectTo,
        sessionId: newSessionId,
        authEnabled,
        hasToken: !!accessToken,
        tokenLength: accessToken ? accessToken.length : 0
      })}`);
      
      if (accessToken) {
        console.error(`Token value (first 10 chars): ${accessToken.substring(0, 10)}...`);
        console.error(`Token length: ${accessToken.length}`);
      } else {
        console.error('Token inspection in full response:');
        try {
          // Look for any properties that might contain the token
          const stringProps = Object.entries(response).filter(([_, v]) => 
            typeof v === 'string' && v.length > 20
          );
          
          if (stringProps.length > 0) {
            stringProps.forEach(([key, value]) => {
              console.error(`Property ${key}: ${value.substring(0, 10)}... (${value.length} chars)`);
            });
          } else {
            console.error('No string properties that might be tokens found in response');
          }
        } catch (err) {
          console.error('Error inspecting response for tokens:', err);
        }
      }
    }
    */
    
    if (accessToken) {
      /* Debug logging - commented out for production
      console.error('✅ CALLING setAccessToken WITH TOKEN');
      */
      setAccessToken(accessToken);
      
      /* Verification logging - commented out for production
      // Verify token was set as cookie (for oatsdev only)
      if (window.location.hostname.includes('oatsdev')) {
        setTimeout(() => {
          const verifiedCookie = getCookie('_cat');
          console.error(`Verification - _cat cookie after initiateAuthFlow:`);
          console.error(`Cookie set: ${verifiedCookie ? 'YES ✅' : 'NO ❌'}`);
          
          if (verifiedCookie) {
            console.error(`Cookie length: ${verifiedCookie.length}`);
            console.error(`Cookie matches token: ${verifiedCookie === accessToken ? 'YES ✅' : 'NO ❌'}`);
          }
          
          console.error('==== API DEBUG FOR OATSDEV END ====');
        }, 500);
      }
      */
    } else {
      /* Debug logging - commented out for production
      console.error('❌ NO TOKEN FOUND IN RESPONSE - NOT SETTING COOKIE');
      if (window.location.hostname.includes('oatsdev')) {
        console.error('==== API DEBUG FOR OATSDEV END ====');
      }
      */
    }

    session = updateSession({
      sid: newSessionId,
      status: CONSTANTS.STATUS_KEYS.KNOWN,
      statusAt: now(),
      authAt: now(),
      params
    })

    if (authEnabled) {
      setAuthInProgress(true)
      setRedirectInProgress()
      redirectAway(redirectTo)
    }
  } catch (e) {
    error(CONSTANTS.MESSAGES.AUTH_CALLBACK_ERROR, e)

    log(
      CONSTANTS.LOG_MESSAGES.AUTH_AMBIGUOUS_ERROR,
      e.message || e,
      e.response,
      getQueryParam(CONSTANTS.AUTH_KEYS.REDIRECT)
    )

    if (e.response?.status >= 400) {
      session = updateSession({
        status: CONSTANTS.STATUS_KEYS.INVALID,
        statusAt: now(),
        params
      })
    }

    redirectAway(getQueryParam(CONSTANTS.AUTH_KEYS.REDIRECT))
  }

  return true
}

/**
 * Check if the request is an authentication request
 * @param {Object} params - Parameters to check for auth-related tokens.
 * @returns {boolean} - True if auth parameters exist, otherwise false.
 */
function isAuthRequest(params) {
  const result = anyParamExists(CONSTANTS.PARAMS.TOKEN_KEYS, params)
  log(CONSTANTS.LOG_MESSAGES.IS_AUTH_REQUEST, result)
  return result
}

/**
 * Perform API Authentication Request
 * @param {Object} jsonData - The JSON payload for the API request.
 * @param {string|null} [sessionId=null] - Optional session ID for authentication.
 * @returns {Object} - Response containing redirectTo and sessionId.
 * @throws {Error} - Throws an error if authentication fails.
 */
export async function apiAuth(jsonData, sessionId = null) {
  setAuthInProgress(true)
  let response

  try {
    response = await post(CONSTANTS.API_ENDPOINTS.CUSTOMER_AUTH, jsonData, sessionId)
  } catch (e) {
    error(CONSTANTS.MESSAGES.AUTH_ENDPOINT_ERROR, e)
    throw e
  }

  const payload = await response.json().catch(e => {
    error(CONSTANTS.MESSAGES.JSON_PARSE_ERROR, e)
    return { message: e.message }
  })

  log(CONSTANTS.LOG_MESSAGES.AUTH_RESPONSE_DATA, payload)

  if (!response.ok) {
    throw createResponseError(payload?.message || response.statusText, response, payload)
  }

  if (!payload?.data) {
    throw createResponseError(CONSTANTS.MESSAGES.AUTH_REDIRECT_ERROR, response, payload)
  }

  const authUrl = payload.data.redirectTo || payload.data
  const accessToken = payload.data.accessToken

  /* Debug log for oatsdev - commented out for production
  if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
    console.error('🔐 AUTH API RESPONSE');
    console.error(`Raw payload: ${JSON.stringify(payload)}`);
    console.error(`Has data: ${payload.data ? 'YES' : 'NO'}`);
    console.error(`Has accessToken in response: ${accessToken ? 'YES' : 'NO'}`);
    
    if (accessToken) {
      console.error(`Token length: ${accessToken.length}`);
      console.error(`Token prefix: ${accessToken.substring(0, 10)}...`);
      console.error('✅ CALLING setAccessToken FROM apiAuth');
    } else {
      console.error('❌ NO TOKEN IN apiAuth RESPONSE');
      console.error('Response data structure:');
      try {
        const dataKeys = Object.keys(payload.data || {});
        console.error(`Keys in payload.data: ${dataKeys.join(', ')}`);
        
        // Check if token might be in a nested property
        if (dataKeys.length > 0) {
          dataKeys.forEach(key => {
            const value = payload.data[key];
            if (typeof value === 'string' && value.length > 20) {
              console.error(`Potential token in '${key}': ${value.substring(0, 10)}... (${value.length} chars)`);
            }
          });
        }
      } catch (err) {
        console.error('Error inspecting payload:', err);
      }
    }
  }
  */
  
  if (accessToken) {
    setAccessToken(accessToken);
    
    /* Double verify for oatsdev - commented out for production
    if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
      // Check after a short delay that cookie was set
      setTimeout(() => {
        const verifiedCookie = getCookie('_cat');
        console.error(`🔎 VERIFICATION: _cat cookie after apiAuth`);
        console.error(`Cookie set: ${verifiedCookie ? 'YES ✅' : 'NO ❌'}`);
        if (verifiedCookie) {
          console.error(`Cookie length: ${verifiedCookie.length}`);
          console.error(`Matches token: ${verifiedCookie === accessToken ? 'YES ✅' : 'NO ❌'}`);
        }
      }, 500);
    }
    */
  }

  const authEnabled = !String(payload.message).toLowerCase().includes('anonymous')

  sessionId = payload.data.sessionId

  log(CONSTANTS.LOG_MESSAGES.AUTH_URL_DETAILS, authUrl, authEnabled, sessionId, payload.message)

  if (!authEnabled || !authUrl.startsWith(window.location.origin)) {
    return { redirectTo: authUrl, sessionId, authEnabled }
  }

  const anchorTag = getAnchorTag(authUrl)

  return fetch(authUrl, {
    credentials: 'include',
    redirect: 'follow',
    method: 'GET',
    headers: CONSTANTS.API_HEADERS.DEFAULT
  }).then(({ url }) => {
    const redirectTo = anchorTag ? `${url}${anchorTag}` : url

    return { redirectTo, sessionId, authEnabled }
  })
}

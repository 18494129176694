import { CONSTANTS } from '../constants'
import { getSession } from './session'
import { post } from './network'
import { log, error } from './logger'

export const RodeoJS = (function () {
  /**
   * Fetch Customer Data based on the provided type
   * @param {string} type - The type of data to fetch (e.g., customer_details, customer_addresses)
   * @returns {Promise<Object|null>} - Returns the data object or null if an error occurs
   */
  async function fetchCustomerData(type) {
    try {
      const session = getSession()
      const sessionId = session?.sid || null
      const payload = { type }

      log(CONSTANTS.LOG_MESSAGES.FETCH_CUSTOMER_REQUEST, type, sessionId)

      const response = await post(CONSTANTS.API_ENDPOINTS.CUSTOMER_DATA, payload, sessionId, true)

      if (response.status === 400) {
        const errorData = await response.json().catch(() => ({ message: CONSTANTS.MESSAGES.UNKNOWN_ERROR }))
        error(CONSTANTS.MESSAGES.SERVER_ERROR_400, type, errorData)
        return null
      }

      if (!response.ok) {
        error(CONSTANTS.MESSAGES.SERVER_UNEXPECTED_ERROR, type, response.statusText, response.status)
        return null
      }

      const data = await response.json()
      log(CONSTANTS.LOG_MESSAGES.FETCH_CUSTOMER_SUCCESS, type, data)

      return data
    } catch (e) {
      error(CONSTANTS.MESSAGES.FETCH_CUSTOMER_ERROR, type, e.message)
      return null
    }
  }

  /**
   * Public API to fetch various customer data
   */
  return {
    async getCustomerDetails() {
      return fetchCustomerData(CONSTANTS.CUSTOMER_DATA_TYPES.DETAILS)
    },
    async getCustomerAddresses() {
      return fetchCustomerData(CONSTANTS.CUSTOMER_DATA_TYPES.ADDRESSES)
    },
    async getCustomerOrders() {
      return fetchCustomerData(CONSTANTS.CUSTOMER_DATA_TYPES.ORDERS)
    },
    async getCustomerBasicDetails() {
      return fetchCustomerData(CONSTANTS.CUSTOMER_DATA_TYPES.BASIC_DETAILS)
    },
    async getCustomerMetafields() {
      return fetchCustomerData(CONSTANTS.CUSTOMER_DATA_TYPES.METAFIELDS)
    },
    async getCustomerLTV() {
      return fetchCustomerData(CONSTANTS.CUSTOMER_DATA_TYPES.LTV)
    },
    async getCustomerStoreCredit() {
      return fetchCustomerData(CONSTANTS.CUSTOMER_DATA_TYPES.STORE_CREDIT)
    },
    async getCustomerCruiseProfile() {
      return fetchCustomerData(CONSTANTS.CUSTOMER_DATA_TYPES.CRUISE_PROFILE)
    }
  }
})()

export const CONSTANTS = {
  WINDOW_KEYS: {
    SCRIPT_LOADED: '__cruiseScriptLoaded',
    PROFILE: 'cruise_profile',
    PATH: 'cruise_path',
    ANALYTICS: 'cruise_analytics'
  },

  AUTH_KEYS: {
    IN_PROGRESS: '__auth_in_progress',
    REDIRECT: 'redirectTo'
  },

  STORAGE_KEYS: {
    SESSION: '__cruise_session',
    PROFILE: '__cruise_profile',
    DEBUG: '__cruise_debug',
    AUTH_REDIRECT: '__cruise_redirect',
    VISITOR_ID: '_rodeo_visitor_id',
    PARAMS: '__cruise'
  },
  COOKIES_KEYS: {
    RODEO_SESSION_ID: '__rodeo_sid',
    RODEO_VISITOR_ID: '_rodeo_visitor_id'
  },

  STATUS_KEYS: {
    KNOWN: 'KNOWN',
    UNKNOWN: 'UNKNOWN',
    EXPIRED: 'EXPIRED',
    INVALID: 'INVALID'
  },

  API_ENDPOINTS: {
    CUSTOMER_AUTH: '/customer/auth',
    CUSTOMER_RESUME: '/customer/auth/resume',
    CUSTOMER_DATA: '/customer',
    TRACK_ACTIVITY: '/track-activity',
    RODEO_VISTOR_SCRIPT: 'https://cruise-control-app-proxy.heyrodeo.ai/rodeo-visitor.js',
    PRODUCT_TITLES: '/product-titles'
  },
  API_HEADERS: {
    SESSION: 'X-Cruise-Session-Id',
    VISITOR_ID: 'X-Rodeo-Visitor-ID',
    DEFAULT: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  },

  EU: {
    COUNTRIES: [
      'AT',
      'BE',
      'BG',
      'CY',
      'CZ',
      'DE',
      'DK',
      'EE',
      'EL',
      'ES',
      'FI',
      'FR',
      'HR',
      'HU',
      'IE',
      'IT',
      'LT',
      'LU',
      'LV',
      'MT',
      'NL',
      'PL',
      'PT',
      'RO',
      'SE',
      'SI',
      'SK'
    ]
  },

  PARAMS: {
    TOKEN_KEYS: ['cruise', 'utm_pid'],
    PROPRIETARY_PARAMS: ['cruise', 'utm_pid', 'redirectTo']
  },

  EVENTS: {
    PRODUCT_VIEWED: 'product_viewed',
    PRODUCT_ADDED_TO_CART: 'product_added_to_cart',
    RODEO_LOGGED_IN: 'rodeo_logged_in'
  },

  MESSAGES: {
    ERROR_FETCH_PROFILE: 'Error fetching cruise profile using RodeoJS:',
    ERROR_VISITOR_ID: 'Failed to fetch visitor ID:',
    ERROR_RESPONSE_JSON: 'Response JSON error:',
    AUTH_AMBIGUOUS: 'Auth ambiguous error:',
    INVALID_URL: 'Invalid URL:',
    REDIRECT_FALLBACK: 'Redirect fallback because URL is invalid:',
    REDIRECT_MISMATCH: 'Redirect fallback because URL is mismatched:',
    AUTH_RESUME_ERROR: 'Error during auth resume:',
    AUTH_CALLBACK_ERROR: 'Auth callback error:',
    AUTH_ENDPOINT_ERROR: 'Auth endpoint error:',
    JSON_PARSE_ERROR: 'Error parsing JSON response:',
    AUTH_RESUME_SESSION_ERROR: 'Auth resume session error',
    AUTH_REDIRECT_ERROR: 'Auth redirect error',
    PROFILE_FETCH_ERROR: 'Error fetching cruise profile.',
    PROFILE_PARSE_ERROR: 'Error parsing cruise profile.',
    FETCH_CUSTOMER_ERROR: 'Error fetching customer data for type:',
    SERVER_ERROR_400: 'Server responded with 400 for type:',
    SERVER_UNEXPECTED_ERROR: 'Unexpected server error for type:',
    UNKNOWN_ERROR: 'Unknown error occurred.',
    POST_REQUEST_ERROR: 'Error:'
  },

  TIMEOUTS: {
    FINGERPRINT_LOAD: 5000,
    FINGERPRINT_INTERVAL: 100,
    REDIRECT_DELAY: 2000,
    SESSION_EXPIRY_DAYS: 30,
    EXPIRATION_DAYS: 30,
    LOGOUT_WINDOW: 30 * 60 * 1000
  },
  CUSTOMER_DATA_TYPES: {
    DETAILS: 'customer_details',
    ADDRESSES: 'customer_addresses',
    ORDERS: 'customer_orders',
    BASIC_DETAILS: 'customer_basic_details',
    METAFIELDS: 'customer_metafields',
    LTV: 'customer_ltv',
    STORE_CREDIT: 'customer_store_credit',
    CRUISE_PROFILE: 'customer_cruise_profile'
  },
  LOG_MESSAGES: {
    IS_AUTH_REQUEST: 'Is auth request:',
    SCRIPT_ALREADY_EXECUTED: 'Script has already been executed. Exiting.',
    TIME_TO_CRUISE: 'Time to cruise!',
    AUTH_INITIATED: 'Auth initiated',
    AUTH_SUCCESSFUL_REDIRECT: 'Auth successful redirect:',
    AUTH_AMBIGUOUS_ERROR: 'Auth ambiguous error:',
    AUTH_RESUME_ERROR: 'Auth resume endpoint error:',
    AUTH_ENDPOINT_ERROR: 'Auth endpoint error:',
    RESPONSE_JSON_ERROR: 'Response JSON error:',
    REDIRECT_FALLBACK_INVALID_URL: 'Redirect fallback because URL is invalid:',
    REDIRECT_FALLBACK_MISMATCH_URL: 'Redirect fallback because URL is mismatched:',
    REDIRECT_AWAY: 'Redirecting away:',
    VISITOR_ID_FETCH_ERROR: 'Failed to fetch visitor ID:',
    FINGERPRINT_TIMEOUT: 'FingerprintJS failed to load within the allotted time.',
    EXPIRED_30_DAYS_SCENARIO: 'EXPIRED 30-day scenario initiated',
    EXPIRED_LOGOUT_SCENARIO: 'EXPIRED Logout scenario initiated',
    UNKNOWN_SCENARIO: 'UNKNOWN Scenario initiated',
    EXPIRED_SCENARIO: 'EXPIRED Scenario initiated',
    CACHED_AUTH_FALLBACK_INITIATED: 'Cached auth fallback initiated',
    CACHED_AUTH_FALLBACK_SKIPPED_NO_SESSION: 'Cached auth fallback skipped: no session ID',
    SHOPIFY_ANALYTICS_UNAVAILABLE: 'Shopify analytics is not available.',
    INVALID_EVENT_DATA: 'Invalid data for event:',
    EVENT_SENT_SUCCESS: 'Event sent successfully:',
    EVENT_ERROR: 'Error sending event:',
    API_RESPONSE: 'API Response:',
    ERROR_FETCHING_CUSTOMER_DATA: 'Unexpected error fetching customer data:',
    SERVER_RESPONSE_400: 'Server response (400):',
    UNEXPECTED_SERVER_ERROR: 'Unexpected server error:',
    FETCH_RESPONSE: 'Response for fetchCustomerData:',
    AUTH_RESPONSE_PAYLOAD: 'Auth response payload:',
    AUTH_DATA: 'Auth data:',
    AUTH_INITIATED: 'Auth flow initiated:',
    AUTH_SUCCESS_REDIRECT: 'Auth successful, redirecting to:',
    AUTH_SCENARIO_CONITNUED: 'Scenario continued',
    AUTH_AMBIGUOUS_ERROR: 'Auth ambiguous error:',
    IS_AUTH_REQUEST: 'Is auth request:',
    AUTH_RESPONSE_DATA: 'Auth response data:',
    AUTH_URL_DETAILS: 'Auth URL details:',
    PRODUCT_CART_EVENT: 'Product added to cart event triggered:',
    PROFILE_EXISTS_OR_AUTH: 'Profile already exists or auth is in progress.',
    PROFILE_FETCH_SUCCESS: 'Fetched and saved Cruise Profile:',
    FETCH_CUSTOMER_REQUEST: 'Fetching customer data for type:',
    FETCH_CUSTOMER_SUCCESS: 'Successfully fetched customer data for type:',
    UPDATE_PARAMS: 'Update params:',
    UPDATE_SESSION: 'Update session:'
  },
  ERROR_MESSAGES: {
    PRODUCT_ID_REQUIRED: 'Product ID is required.',
    PRODUCT_TITLE_REQUIRED: 'Product title is required.',
    VALID_PRICE_REQUIRED: 'Valid price is required.',
    PRODUCT_URL_REQUIRED: 'Product URL is required.',
    VALID_QUANTITY_REQUIRED: 'Valid quantity is required.',
    VALID_CART_AMOUNT_REQUIRED: 'Valid cart amount is required.'
  }
}

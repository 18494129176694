import { compactObject, serialize, getCurrentUrl } from './helpers'

/**
 * Retrieve state with JSON parsing
 * @param {string} key - Key in localStorage
 * @returns {object|null} - Parsed JSON object
 */
export function getState(key) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }

  const data = localStorage.getItem(key)
  if (!data) return null
  return JSON.parse(data)
}

/**
 * Save state with JSON serialization
 * @param {string} key - Key in localStorage
 * @param {object} data - Data to save (JSON object)
 * @returns {object|null} - Saved data
 */
export function setState(key, data) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }
  data = compactObject(data)
  if (!data || !Object.keys(data).length) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, serialize(data))
  }
  return data
}

/**
 * Retrieve state without JSON parsing
 * @param {string} key - Key in localStorage
 * @returns {string|null} - Raw string value
 */
export function getRawState(key) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }
  return localStorage.getItem(key) || null
}

/**
 * Save state without JSON serialization
 * @param {string} key - Key in localStorage
 * @param {string} value - Raw string value to save
 */
export function setRawState(key, value) {
  // Return an empty string if localStorage is not supported
  if (typeof localStorage === 'undefined') {
    return ''
  }
  if (typeof value !== 'string' || !value.trim()) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }
}

export function removeItemFromLocalStorage(key) {
  if (localStorage !== undefined) {
    localStorage.removeItem(key)
  }
}

// Attempts to remove an item from localStorage and logs failure if any
export function safelyRemoveItemFromLocalStorage(key) {
  try {
    removeItemFromLocalStorage(key)
  } catch (error) {
    logError('Failed to remove item from localStorage', { key, error })
  }
}

// Retrieves an object containing all local storage items
export function getAllLocalStorageItems() {
  try {
    const localStorageItems = {}
    const storage = localStorage

    if (storage) {
      for (let i = 0; i < storage.length; i++) {
        const key = storage.key(i)
        if (key) {
          localStorageItems[key] = getLocalStorageItem(key)
        }
      }
    }

    return localStorageItems
  } catch {
    return {}
  }
}

/**
 * Helper function to set a cookie for 30 days or expire it.
 * @param {string} name - The cookie name.
 * @param {string} value - The cookie value.
 * @param {number} [days=30] - Number of days until expiration. Use negative value to expire immediately.
 */
export const setCookie = (name, value, days = 30) => {
  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString()
  const currentUrl = getCurrentUrl()
  
  /* Debug logging - commented out for production
  if (currentUrl.hostname && currentUrl.hostname.includes('oatsdev')) {
    console.error('==== SETTING COOKIE ====');
    console.error(`Current hostname: ${currentUrl.hostname}`);
    console.error(`Cookie name: ${name}`);
    console.error(`Cookie value length: ${value ? value.length : 0}`);
    console.error(`Cookie value prefix: ${value ? value.substring(0, 10) + '...' : 'NULL'}`);
  }
  */
  
  // IMPORTANT NOTE FOR PRODUCTION: 
  // This approach sets the cookie for the current domain only (e.g., www.oatsovernight.com).
  // For cross-subdomain sharing in production (between www and app subdomains):
  //
  // 1. On production, the cookie script must be loaded from the root domain (oatsovernight.com)
  //    OR from each subdomain that needs access.
  //
  // 2. Alternative approach: Use a separate auth service on the root domain
  //    that sets the cookie with domain=".oatsovernight.com" 
  
  // SIMPLEST SOLUTION: Just set the cookie without a domain attribute
  // This always applies to the current host only and is most secure
  const cookieString = `${name}=${value}; path=/; expires=${expires}; Secure; SameSite=None`;
  
  try {
    // Set the main cookie
    document.cookie = cookieString;
    
    /* Debug for oatsdev only - commented out for production
    if (currentUrl.hostname && currentUrl.hostname.includes('oatsdev')) {
      console.error(`Set cookie with string: ${name}=... (${value ? value.length : 0} chars)`);
    }
    
    // Test cookie variations were previously here
    // Now we're only setting the main _cat cookie for production
    */
  } catch (e) {
    /* Error logging - uncomment for troubleshooting
    if (currentUrl.hostname && currentUrl.hostname.includes('oatsdev')) {
      console.error(`Error setting cookie: ${e.message}`);
      console.error(`Error stack: ${e.stack}`);
      console.error('==== SETTING COOKIE FAILED ====');
    }
    */
  }
}

const getRootDomain = hostname => {
  // Special case for myshopify.com domains - always use myshopify.com
  if (hostname.includes('myshopify.com')) {
    return 'myshopify.com'
  }

  const parts = hostname.split('.')

  // If domain has more than 2 parts (e.g., www.oatsovernight.com or portal.oatsovernight.com)
  if (parts.length > 2) {
    // Get the root domain (e.g., oatsovernight.com)
    // This ensures cookies can be shared across subdomains
    return parts.slice(-2).join('.')
  }

  return hostname // Already a root domain (e.g., example.com)
}

/**
 * Helper function to get all cookies as an object or retrieve a specific cookie by name.
 * @param {string} [name] - The cookie name to retrieve. If omitted, returns all cookies as an object.
 * @returns {string|Object|null} - The cookie value if a name is provided, an object of all cookies if omitted, or null if the cookie does not exist.
 */
export const getCookie = name => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=')
    if (key && value) {
      acc[key] = decodeURIComponent(value)
    }
    return acc
  }, {})

  return cookies[name] || null
}

export function setAccessToken(accessToken) {
  /* Debug for oatsdev only - commented out for production
  if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
    console.error('🍪 setAccessToken CALLED');
    console.error(`Token provided: ${accessToken ? 'YES (length: ' + accessToken.length + ')' : 'NO'}`);
    console.error(`Call stack: ${new Error().stack}`);
  }
  
  // Debug for oatsdev store only
  if (window.location.hostname.includes('oatsdev')) {
    console.error('==== CLIENT-SIDE DEBUG FOR OATSDEV ====');
    console.error(`Setting _cat cookie with token: ${accessToken ? accessToken.substring(0, 10) + '...' : 'NULL'}`);
    console.error(`Token length: ${accessToken ? accessToken.length : 0}`);
    console.error(`Current cookies BEFORE setting: ${document.cookie}`);
    
    // Log cookies that might already be set
    const existingCat = getCookie('_cat');
    if (existingCat) {
      console.error(`Existing _cat cookie found: ${existingCat.substring(0, 10)}... (${existingCat.length} chars)`);
    } else {
      console.error('No existing _cat cookie found');
    }
  }
  */
  
  if (!accessToken) {
    /* Debug log
    if (window.location.hostname.includes('oatsdev')) {
      console.error('⚠️ Not setting cookie - no access token provided');
    }
    */
    return;
  }
  
  try {
    // Set the cookie
    setCookie('_cat', accessToken, 30);
    
    /* Verification logging - uncomment for troubleshooting
    if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
      console.error('✅ setCookie called successfully');
      
      // Verify if cookie was actually set
      setTimeout(() => {
        const verifiedCookie = getCookie('_cat');
        console.error(`Cookie verification after setAccessToken:`);
        console.error(`- _cat: ${verifiedCookie ? 'SET ✅' : 'NOT SET ❌'} ${verifiedCookie ? `(${verifiedCookie.length} chars)` : ''}`);
        console.error(`- Matches original: ${verifiedCookie === accessToken ? 'YES ✅' : 'NO ❌'}`);
        console.error(`All cookies AFTER setting: ${document.cookie}`);
        console.error('==== CLIENT-SIDE DEBUG FOR OATSDEV COMPLETED ====');
      }, 500);
    }
    */
  } catch (e) {
    /* Error logging - uncomment for troubleshooting
    if (window.location.hostname && window.location.hostname.includes('oatsdev')) {
      console.error('❌ Error setting cookie:', e.message);
      console.error(`Error stack: ${e.stack}`);
      console.error('==== CLIENT-SIDE DEBUG FOR OATSDEV FAILED ====');
    }
    */
  }
}
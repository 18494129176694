import { log, error } from './utils/logger'
import { CONSTANTS } from './constants'
import { getSession, updateSession, expireSession } from './utils/session'
import {
  removeTokensFromCurrentLocation,
  getQueryParam,
  compactObject,
  now,
  daysAgo,
  redirectAway
} from './utils/helpers'
import { apiAuthResume, initiateAuthFlow } from './utils/api'
import { setAccessToken } from './utils/storage'

/**
 * Boot Session Initialization
 */
export async function bootSession(SIGNED_IN, CURRENT_PROFILE, IS_CLICK, CURRENT_PARAMS, CURRENT_URL) {
  try {
    /* Debug - only for oatsdev - commented out for production
    if (CURRENT_URL.hostname && CURRENT_URL.hostname.includes('oatsdev')) {
      console.error('🔄 BOOT SESSION TRIGGERED');
      console.error('Boot session params:', { 
        SIGNED_IN, 
        'Profile Type': CURRENT_PROFILE.is_visitor ? 'Visitor' : 'Customer',
        IS_CLICK, 
        'URL': CURRENT_URL.href 
      });
      
      // Debug login forms
      console.error('🔍 CHECKING FOR LOGIN FORMS');
      const customerLoginForms = document.querySelectorAll('form[action*="/account/login"]');
      if (customerLoginForms.length > 0) {
        console.error(`✅ Found ${customerLoginForms.length} customer login forms`);
        
        // Log details of each form
        customerLoginForms.forEach((form, index) => {
          console.error(`Form #${index + 1} action: ${form.getAttribute('action')}`);
          console.error(`Form #${index + 1} method: ${form.getAttribute('method')}`);
          console.error(`Form #${index + 1} inputs: ${form.querySelectorAll('input').length}`);
        });
      } else {
        console.error('❌ No customer login forms found on page');
      }
    }
    */
    
    log(CONSTANTS.LOG_MESSAGES.TIME_TO_CRUISE)

    let session = getSession()

    // Remove proprietary query string values right away
    removeTokensFromCurrentLocation()

    // Handle already signed-in scenarios
    if (SIGNED_IN) {
      let redirectAwayUrl = getQueryParam(CONSTANTS.AUTH_KEYS.REDIRECT)

      // Check for missing session ID, mismatched customer ID, or click parameters
      if (!session.sid || (session.cid && session.cid !== CURRENT_PROFILE.id) || IS_CLICK) {
        const authResumeParams = compactObject({
          ...(IS_CLICK ? CURRENT_PARAMS : getSession(CONSTANTS.STORAGE_KEYS.PARAMS)),
          url: encodeURIComponent(CURRENT_URL.href)
        })

        try {
          const { sessionId, redirectTo, accessToken } = await apiAuthResume(authResumeParams, session.sid)

          if (accessToken) {
            setAccessToken(accessToken)
          }

          updateSession({
            sid: sessionId,
            params: authResumeParams
          })

          redirectAwayUrl = redirectTo
        } catch (err) {
          error(CONSTANTS.MESSAGES.AUTH_RESUME_ERROR, err)
        }
      }

      session = updateSession({
        cid: CURRENT_PROFILE.id,
        status: CONSTANTS.STATUS_KEYS.KNOWN,
        statusAt: now(),
        authViewAt: now(),
        expiredAt: null
      })

      if (redirectAwayUrl) {
        redirectAway(redirectAwayUrl)
      }

      return
    }

    // Handle session expiration scenarios
    const authViewDays = daysAgo(session.authViewAt)
    if (authViewDays > CONSTANTS.TIMEOUTS.EXPIRATION_DAYS) {
      log(CONSTANTS.LOG_MESSAGES.EXPIRED_30_DAYS_SCENARIO, authViewDays)
      session = expireSession()
    }

    // Handle different session statuses
    if (session.status === CONSTANTS.STATUS_KEYS.KNOWN) {
      const isLogout = session.authViewAt && now() - session.authViewAt < CONSTANTS.TIMEOUTS.LOGOUT_WINDOW

      if (isLogout) {
        log(CONSTANTS.LOG_MESSAGES.EXPIRED_LOGOUT_SCENARIO)
        session = expireSession()
      } else {
        log(CONSTANTS.LOG_MESSAGES.UNKNOWN_SCENARIO)
        session = updateSession({
          status: CONSTANTS.STATUS_KEYS.UNKNOWN,
          statusAt: now()
        })
      }
    } else {
      const status = session.status || CONSTANTS.STATUS_KEYS.UNKNOWN

      log(`${status} ${CONSTANTS.LOG_MESSAGES.AUTH_SCENARIO_CONITNUED}`)
      session = updateSession({
        status,
        statusAt: now()
      })
    }

    log(CONSTANTS.LOG_MESSAGES.AUTH_INITIATED)

    // Handle authentication flow
    /* Debug logging - commented out for production
    if (CURRENT_URL.hostname && CURRENT_URL.hostname.includes('oatsdev')) {
      console.error('⚙️ ATTEMPTING TO INITIATE AUTH FLOW');
      console.error('Current params:', CURRENT_PARAMS);
      console.error('Current session:', session);
    }
    */
    
    await initiateAuthFlow(CURRENT_PARAMS).then(initiated => {
      /* Debug logging - commented out for production
      if (CURRENT_URL.hostname && CURRENT_URL.hostname.includes('oatsdev')) {
        console.error('🔑 Auth flow initiated result:', initiated);
      }
      */
      
      if (initiated) {
        return initiated
      }

      const { sid, status } = session
      /* Debug logging - commented out for production
      if (CURRENT_URL.hostname && CURRENT_URL.hostname.includes('oatsdev')) {
        console.error('Session ID for fallback auth:', sid);
        console.error('Session status:', status);
      }
      */

      if (!sid) {
        /* Debug logging - commented out for production
        if (CURRENT_URL.hostname && CURRENT_URL.hostname.includes('oatsdev')) {
          console.error('❌ No session ID - skipping cached auth fallback');
        }
        */
        log(CONSTANTS.LOG_MESSAGES.CACHED_AUTH_FALLBACK_SKIPPED_NO_SESSION)
        return false
      }

      /* Debug logging - commented out for production
      if (CURRENT_URL.hostname && CURRENT_URL.hostname.includes('oatsdev')) {
        console.error('🔄 Attempting cached auth fallback');
      }
      */
      log(CONSTANTS.LOG_MESSAGES.CACHED_AUTH_FALLBACK_INITIATED)

      const authFlowParams = compactObject(IS_CLICK ? CURRENT_PARAMS : getSession(CONSTANTS.STORAGE_KEYS.PARAMS))
      /* Debug logging - commented out for production
      if (CURRENT_URL.hostname && CURRENT_URL.hostname.includes('oatsdev')) {
        console.error('Auth flow params for fallback:', authFlowParams);
      }
      */

      return initiateAuthFlow(authFlowParams, sid)
    })
  } catch (err) {
    error(CONSTANTS.MESSAGES.AUTH_CALLBACK_ERROR, err)
  }
}

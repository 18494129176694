import { CONSTANTS } from '../constants'
import { getSession } from './session'
import { log, error as logError } from './logger'
import { post } from './network'

/**
 * RodeoAnalytics Module: Handles publishing events with validation
 */
export const RodeoAnalytics = {
  /**
   * Publish an event to the server with validation.
   * @param {string} eventName - Name of the event (e.g., 'product_viewed', 'product_added_to_cart').
   * @param {object} eventData - The event data payload (e.g., product details, cart info).
   */
  publish(eventName, eventData) {
    const sessionId = getSession()?.sid

    // Validate the event data
    const validationResult = this.validateEvent(eventName, eventData)

    if (!validationResult.isValid) {
      logError(`Invalid data for event "${eventName}":`, validationResult.errors)
      this.logInvalidEvent(eventName, eventData, validationResult.errors)
      return
    }

    const fullEventData = {
      event_name: eventName,
      customer_profile: window[CONSTANTS.WINDOW_KEYS.PROFILE] || {}, // Fallback to an empty object if undefined
      ...eventData
    }

    post(CONSTANTS.API_ENDPOINTS.TRACK_ACTIVITY, fullEventData, sessionId)
      .then(response => response.json())
      .then(data => log(CONSTANTS.LOG_MESSAGES.EVENT_SENT_SUCCESS, eventName, data))
      .catch(err => logError(CONSTANTS.LOG_MESSAGES.EVENT_ERROR, eventName, err))
  },

  /**
   * Validate event data based on the event type.
   * @param {string} eventName - The name of the event.
   * @param {object} eventData - The event data payload.
   * @returns {object} - Validation result with `isValid` boolean and `errors` array.
   */
  validateEvent(eventName, eventData) {
    const errors = []

    switch (eventName) {
      case CONSTANTS.EVENTS.PRODUCT_VIEWED:
        this.validateProductViewed(eventData, errors)
        break

      case CONSTANTS.EVENTS.PRODUCT_ADDED_TO_CART:
        this.validateProductAddedToCart(eventData, errors)
        break

      default:
        errors.push(`Unsupported event type: ${eventName}`)
    }

    return {
      isValid: errors.length === 0,
      errors
    }
  },

  /**
   * Validate `product_viewed` event data.
   * @param {object} eventData
   * @param {array} errors
   */
  validateProductViewed(eventData, errors) {
    const { productDetails } = eventData
    if (!productDetails || !productDetails.id) errors.push(CONSTANTS.ERROR_MESSAGES.PRODUCT_ID_REQUIRED)
    if (!productDetails.title) errors.push(CONSTANTS.ERROR_MESSAGES.PRODUCT_TITLE_REQUIRED)
    if (isNaN(productDetails.price)) errors.push(CONSTANTS.ERROR_MESSAGES.VALID_PRICE_REQUIRED)
    if (!productDetails.url) errors.push(CONSTANTS.ERROR_MESSAGES.PRODUCT_URL_REQUIRED)
  },

  /**
   * Validate `product_added_to_cart` event data.
   * @param {object} eventData
   * @param {array} errors
   */
  validateProductAddedToCart(eventData, errors) {
    const { productDetails, quantity, cost } = eventData
    if (!productDetails || !productDetails.id) errors.push(CONSTANTS.ERROR_MESSAGES.PRODUCT_ID_REQUIRED)
    if (!productDetails.title) errors.push(CONSTANTS.ERROR_MESSAGES.PRODUCT_TITLE_REQUIRED)
    if (isNaN(productDetails.price)) errors.push(CONSTANTS.ERROR_MESSAGES.VALID_PRICE_REQUIRED)
    if (!productDetails.url) errors.push(CONSTANTS.ERROR_MESSAGES.PRODUCT_URL_REQUIRED)
    if (!quantity || isNaN(quantity)) errors.push(CONSTANTS.ERROR_MESSAGES.VALID_QUANTITY_REQUIRED)
    if (!cost || isNaN(cost.amount)) errors.push(CONSTANTS.ERROR_MESSAGES.VALID_CART_AMOUNT_REQUIRED)
  },

  /**
   * Log invalid event data for further analysis.
   * @param {string} eventName - Name of the event.
   * @param {object} eventData - The event data payload.
   * @param {array} errors - Array of validation errors.
   */
  logInvalidEvent(eventName, eventData, errors) {
    logError(`Invalid event logged: ${eventName}`, eventData, 'Errors:', errors)
  }
}

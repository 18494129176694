import { CONSTANTS } from '../constants'
import { getVisitorID } from './fingerprint'
import { isCountryInEU, compactObject, serialize, getApiEndpoint } from './helpers'
import { error } from './logger'
import LZStringUtil from './lzstring'

/**
 * Performs a POST request to a specified API endpoint.
 *
 * @param {string} endpoint - The API endpoint to send the request to.
 * @param {object} data - The payload data to send in the request body.
 * @param {string|null} sessionId - The session ID for authentication headers.
 * @param {boolean} compressFlag - If true, compress the data before sending.
 * @returns {Promise<Response>} - The response from the API.
 */
export async function post(endpoint, data, sessionId = null, compressFlag = false) {
  try {
    // Extract query parameters from the endpoint
    const queryParams = new URLSearchParams(endpoint.split('?')[1] || '')
    const isRodeoTest = queryParams.get('rodeo') === 'test'

    // Check Shopify country
    const shopifyCountry = window.Shopify && window.Shopify.country ? window.Shopify.country : null
    let isEU = false

    if (shopifyCountry) {
      isEU = isCountryInEU(shopifyCountry) // Check if country is in the EU
    }

    // Fetch Rodeo Visitor ID
    const rodeoVisitorId = isEU || isRodeoTest ? null : await getVisitorID()

    // Construct headers for the request
    const headers = compactObject({
      ...CONSTANTS.API_HEADERS.DEFAULT,
      [CONSTANTS.API_HEADERS.SESSION]: sessionId,
      [CONSTANTS.API_HEADERS.VISITOR_ID]: rodeoVisitorId || undefined
    })

    const apiBaseURL = getApiEndpoint()

    // Handle compression if compressFlag is true
    const payload = compressFlag
      ? { p: LZStringUtil.compressToBase64(JSON.stringify(data)) } // Compress and wrap in `p`
      : data // Use the original data

    // Perform the POST request
    const response = await fetch(apiBaseURL + endpoint, {
      keepalive: true,
      credentials: 'include',
      method: 'POST',
      headers,
      body: serialize(payload) // Serialize the payload
    })

    // Return the response for handling in the calling function
    return response
  } catch (err) {
    // Log and rethrow the error
    error(CONSTANTS.MESSAGES.POST_REQUEST_ERROR, err)
    throw err
  }
}

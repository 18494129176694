import { CONSTANTS } from '../constants'
import { setState, getState } from './storage'
import { isAuthInProgress, cruiseToDom } from './helpers'
import { RodeoJS } from './rodeojs' // Assumed RodeoJS is imported
import { log, error } from './logger'

/**
 * Key for storing the cruise profile in localStorage
 */
const STORAGE_PROFILE_KEY = CONSTANTS.STORAGE_KEYS.PROFILE

/**
 * Profile version - increment this value to force profile refresh for all users
 * 
 * Version history:
 * 1: Initial version
 * 2: ENG-1431 - Force refresh to fix misattribution issue
 */
const PROFILE_VERSION = 2

/**
 * Main logic to handle Cruise Profile
 */
export async function handleCruiseProfile() {
  // Skip fetching if a profile exists or auth is in progress
  if (hasCruiseProfile()) {
    const profile = getState(STORAGE_PROFILE_KEY)
    window.CURRENT_PROFILE = profile
    window.cruise_profile = profile
    
    log(CONSTANTS.LOG_MESSAGES.PROFILE_EXISTS_OR_AUTH)
    return
  }
  await fetchCruiseProfile()
}

/**
 * Fetch Cruise Profile from database
 */
async function fetchCruiseProfile() {
  try {
    const response = await RodeoJS.getCustomerCruiseProfile() // Fetch customer details
    const cruiseProfile = response?.cruise_profile

    if (cruiseProfile?.email) {
      // Add version to the profile
      cruiseProfile.version = PROFILE_VERSION
      
      // Save the profile to localStorage
      setState(STORAGE_PROFILE_KEY, cruiseProfile)

      // Update the global profile on the window object
      window.CURRENT_PROFILE = cruiseProfile
      window.cruise_profile = cruiseProfile
      cruiseToDom(cruiseProfile)

      log(CONSTANTS.LOG_MESSAGES.PROFILE_FETCH_SUCCESS, cruiseProfile)
    } else {
      // Save an empty fallback if email is missing
      if (window.cruise_profile) {
        // Ensure version is updated if using fallback
        window.cruise_profile.version = PROFILE_VERSION
        setState(STORAGE_PROFILE_KEY, window.cruise_profile)
      }
    }
  } catch (e) {
    error(CONSTANTS.MESSAGES.PROFILE_FETCH_ERROR, e)
  }
}

/**
 * Check if a valid Cruise Profile exists in localStorage
 * @returns {boolean} - True if a valid profile exists, otherwise false
 */
function hasCruiseProfile() {
  const profile = getState(STORAGE_PROFILE_KEY)

  if (!profile) {
    return false // No profile found
  }
  
  // Check profile version - force refresh if version doesn't match
  if (!profile.version || profile.version !== PROFILE_VERSION) {
    log('Profile version mismatch. Forcing refresh.', {
      stored_version: profile.version || 'undefined',
      current_version: PROFILE_VERSION
    })
    return false
  }

  cruiseToDom(profile)

  try {
    return profile.email && profile.email.trim() !== ''
  } catch (e) {
    error(CONSTANTS.MESSAGES.PROFILE_PARSE_ERROR, e)
    return false
  }
}

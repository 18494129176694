import detectIncognito from 'detectincognitojs'

/**
 * Wrapper function to handle incognito detection gracefully.
 * @returns {Promise<{isPrivate: boolean, browserName: string}>}
 */
export async function getBrowserInfo() {
  try {
    const result = await detectIncognito()
    return result // { isPrivate: boolean, browserName: string }
  } catch (error) {
    console.error('Error detecting incognito mode:', error)
    return { isPrivate: false, browserName: 'Unknown' } // Default fallback
  }
}

export default getBrowserInfo

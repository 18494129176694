import { getState, setState, setCookie, getCookie } from './storage'
import { CONSTANTS } from '../constants'
import { log } from './logger'
import { filterObject, now } from './helpers'

/**
 * Retrieve the current session from storage.
 * @returns {Object} - Session data object.
 *
 * @example
 * {
 *    cid: customer ID
 *    status: KNOWN | UNKNOWN | EXPIRED
 *    statusAt: Last status update timestamp
 *    authInitAt: Last auth initiation timestamp
 *    authAt: Last auth callback timestamp
 *    authViewAt: Last auth view timestamp
 *    expiredAt: Timestamp for explicit logout
 *    initAt: Timestamp when session was created
 * }
 */
export function getSession() {
  return getState(CONSTANTS.STORAGE_KEYS.SESSION) || {}
}

/**
 * Update the session data and save it to storage.
 * @param {Object} updates - The updates to apply to the session.
 * @param {Object|null} [updates.params] - Parameters to update or remove.
 * @returns {Object} - The updated session data.
 */
export function updateSession({ params, ...updates }) {
  // Handle parameters: remove or update them
  if (typeof params !== 'undefined') {
    const existingParams = getState(CONSTANTS.STORAGE_KEYS.PARAMS)
    log(CONSTANTS.LOG_MESSAGES.UPDATE_PARAMS, params, existingParams)

    const filteredParams = filterObject(params, CONSTANTS.PARAMS.TOKEN_KEYS, true)
    setState(CONSTANTS.STORAGE_KEYS.PARAMS, filteredParams)
  }

  // Get the current session
  const currentSession = getSession()

  // Add initialization timestamp if not already present
  if (!currentSession.initAt) {
    updates.initAt = now()
  }

  // Merge the updates into the current session
  const updatedSession = {
    ...currentSession,
    ...updates
  }

  // Save the updated session to storage
  setState(CONSTANTS.STORAGE_KEYS.SESSION, updatedSession)

  if (updatedSession.sid) {
    setCookie(CONSTANTS.COOKIES_KEYS.RODEO_SESSION_ID, updatedSession.sid) // Store `sid` in cookies
  }

  log(CONSTANTS.LOG_MESSAGES.UPDATE_SESSION, updates, updatedSession, currentSession)

  return updatedSession
}

export function expireSession() {
  log(CONSTANTS.LOG_MESSAGES.EXPIRED_SCENARIO)
  // Remove session ID (`sid`) from both storage and cookies
  setCookie(CONSTANTS.COOKIES_KEYS.RODEO_SESSION_ID, '', -1)
  return updateSession({
    sid: null,
    status: CONSTANTS.STATUS_KEYS.EXPIRED,
    statusAt: now(),
    expiredAt: now()
  })
}

/**
 * Syncs the local storage session ID with the cookie session ID and vice versa
 * Ensures consistency between local storage and cookies, covering edge cases.
 */
export function syncSession() {
  try {
    const storageSession = getSession()
    const storageSessionID = storageSession?.sid || null
    const cookieSessionID = getCookie(CONSTANTS.COOKIES_KEYS.RODEO_SESSION_ID)

    // If storageSession does not have the sid but it exists in cookies, update local storage
    if (!storageSessionID && cookieSessionID) {
      storageSession.sid = cookieSessionID
      setState(CONSTANTS.STORAGE_KEYS.SESSION, storageSession)
    }

    // If we have the session ID in local storage but it's missing in cookies, update the cookie
    if (storageSessionID && !cookieSessionID) {
      setCookie(CONSTANTS.COOKIES_KEYS.RODEO_SESSION_ID, storageSessionID, 30)
    }
  } catch (error) {}
}

import { post } from './utils/network'
import { CONSTANTS } from './constants'
import { getSession } from './utils/session'
import { log, error as logError } from './utils/logger'
import { isShopify } from './utils/helpers'

// Reusable function for publishing events
async function publishEvent(endpoint, eventData, sessionId) {
  try {
    const response = await post(endpoint, eventData, sessionId)
    const data = await response.json()
    log(CONSTANTS.LOG_MESSAGES.API_RESPONSE, data)
  } catch (err) {
    logError(CONSTANTS.LOG_MESSAGES.EVENT_ERROR, err);
  }
}

/**
 * Publish "Rodeo Logged In" Event
 */
export function publishRodeoLoggedInEvent() {
  if (isShopify()) {
    Shopify.analytics.publish(CONSTANTS.EVENTS.RODEO_LOGGED_IN)
  } else {
    log(CONSTANTS.MESSAGES.SHOPIFY_ANALYTICS_UNAVAILABLE)
    const sessionId = getSession()?.sid

    const eventData = {
      event_name: CONSTANTS.EVENTS.RODEO_LOGGED_IN,
      customer_profile: window[CONSTANTS.WINDOW_KEYS.PROFILE],
      source: 'rodeo_events' // Identifier for the source of this event
    }

    publishEvent(CONSTANTS.API_ENDPOINTS.TRACK_ACTIVITY, eventData, sessionId)
  }
}

/**
 * Pixel Event Subscriptions
 */
const PIXEL_SUBSCRIPTIONS = {
  product_viewed(event) {
    const sessionId = getSession()?.sid
    const { products, shop } = event
    const { id, title, vendor, price, variantTitle } = products[0]

    const eventData = {
      event_name: CONSTANTS.EVENTS.PRODUCT_VIEWED,
      customer_profile: window[CONSTANTS.WINDOW_KEYS.PROFILE],
      productDetails: {
        id,
        url: event.url,
        title,
        vendor,
        price,
        currency: shop.currency
      },
      variantTitle,
      source: 'rodeo_events' // Identifier for the source of this event
    }

    publishEvent(CONSTANTS.API_ENDPOINTS.TRACK_ACTIVITY, eventData, sessionId)
  },

  product_added_to_cart(event) {
    log(CONSTANTS.LOG_MESSAGES.PRODUCT_CART_EVENT, event)

    const sessionId = getSession()?.sid
    const { cart, currentLine } = event
    const { id, handle, title, vendor } = currentLine.merchandise.product

    const eventData = {
      event_name: CONSTANTS.EVENTS.PRODUCT_ADDED_TO_CART,
      customer_profile: window[CONSTANTS.WINDOW_KEYS.PROFILE],
      cost: {
        amount: cart.cost.totalAmount.amount,
        currency: cart.cost.totalAmount.currencyCode
      },
      productDetails: {
        id,
        url: handle,
        title,
        vendor,
        price: currentLine.merchandise.price.amount
      },
      variantTitle: currentLine.merchandise.title,
      quantity: currentLine.quantity,
      source: 'rodeo_events' // Identifier for the source of this event
    }

    publishEvent(CONSTANTS.API_ENDPOINTS.TRACK_ACTIVITY, eventData, sessionId)
  }
}

/**
 * Boot Analytics
 * This function sets up analytics subscriptions and processes queued events.
 */
export function bootAnalytics() {
  const analyticsProxy = {
    registered: [],
    push(...payloads) {
      payloads.forEach(payload => {
        this.registered.push(payload)

        const { analytics, integration } = payload

        // Subscribe to pixel events
        Object.entries(PIXEL_SUBSCRIPTIONS).forEach(([eventName, handler]) => analytics.subscribe(eventName, handler))

        // Mark integration as ready
        integration.ready()
      })
    }
  }

  const queue = window[CONSTANTS.WINDOW_KEYS.ANALYTICS] || (window[CONSTANTS.WINDOW_KEYS.ANALYTICS] = analyticsProxy)

  if (queue !== analyticsProxy) {
    window[CONSTANTS.WINDOW_KEYS.ANALYTICS] = analyticsProxy

    // Process any queued events
    analyticsProxy.push(...queue)
  }
}
